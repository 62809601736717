import { Box, Flex, Icon, Text } from "@chakra-ui/react";
import { useGoogleMapsContext } from "../hooks/useGoogleMaps";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ChevronLeftIcon } from "@chakra-ui/icons";
import { GoogleMap } from "@react-google-maps/api";
import { useDisplay } from "../hooks/useDisplay";

export default function Root() {
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const { isLoaded } = useGoogleMapsContext();
  const { isMobile } = useDisplay();

  const navigate = useNavigate();

  const urlSearchParams = new URLSearchParams(window.location.search);

  const centerUrl = urlSearchParams.get("center");
  if (!centerUrl) {
    throw new Error("Missing center");
  }
  const [lat, lng] = centerUrl.split(",").map((c) => parseFloat(c));

  useEffect(() => {
    if (!map) return;
    const panorama: google.maps.StreetViewPanorama = map.getStreetView()!;
    panorama.setPosition({ lat, lng });
    panorama.setPov({
      heading: 265,
      pitch: 0,
    });
    panorama.setOptions({
      enableCloseButton: false,
      motionTracking: false,
      motionTrackingControl: false,
      fullscreenControl: false,
    });
    panorama.setVisible(true);
  }, [map, lat, lng]);

  const onCloseStreetview = () => {
    navigate(-1);
  };

  const onLoad = useCallback(
    (map: google.maps.Map) => {
      setMap(map);
    },
    [setMap]
  );

  const onUnmount = useCallback(() => {
    setMap(null);
  }, [setMap]);

  return (
    <Flex direction="column" w="100%" h="100vh" overflowY="hidden">
      <Box flexDir="row" p="4">
        <Flex
          as="button"
          flexDir="row"
          alignItems="center"
          onClick={onCloseStreetview}
          gap="4"
        >
          <Icon as={ChevronLeftIcon} color="brandDark" boxSize="6" />
          <Text>Retour</Text>
        </Flex>
      </Box>
      {isLoaded ? (
        <GoogleMap
          mapContainerStyle={{
            height: "100%",
            width: "100%",
          }}
          center={{ lat, lng }}
          options={{
            mapTypeControl: false,
            fullscreenControl: false,
            zoomControl: !isMobile,
            streetViewControl: !isMobile,
            gestureHandling: "greedy",
          }}
          onLoad={onLoad}
          onUnmount={onUnmount}
        ></GoogleMap>
      ) : null}
    </Flex>
  );
}
