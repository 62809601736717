import { usePostHog } from "posthog-js/react";
import { useCallback } from "react";
import { AnalyticsEvent } from "./events";

export function useAnalytics() {
  const posthog = usePostHog();

  const sendEvent = useCallback(
    async <T extends AnalyticsEvent>(event: T) => {
      console.debug({ name: event.name, properties: event.properties });
      posthog.capture(event.name, { ...event.properties });
    },
    [posthog]
  );

  return {
    sendEvent,
  };
}
