import { Point } from "leaflet";
import { Popup } from "react-leaflet";
import { useNavigate } from "react-router-dom";
import { useAnalytics } from "../../libs/analytics";
import { useCallback } from "react";
import { MapsDangerPressed } from "../../libs/analytics/events";
import { dangerLabel } from "../../utils/formatDanger";
import { GeoPoint } from "../types";
import { Button, Text, VStack } from "@chakra-ui/react";

type Props = {
  iconSize: number[];
  position: GeoPoint;
  restrictions: {
    weight?: number;
    width?: number;
    height?: number;
    length?: number;
  };
};

export const PopupDanger = ({ iconSize, restrictions, position }: Props) => {
  const navigate = useNavigate();
  const { sendEvent } = useAnalytics();
  const height = iconSize[1];
  const { title, subtitle } = dangerLabel(restrictions);

  const onClick = useCallback(() => {
    sendEvent<MapsDangerPressed>({
      name: "maps:danger_cta:pressed",
      properties: {
        restrictions,
        position,
        url: `https://app.roadroamer.org/streetview?center=${position.lat},${position.lng}`,
      },
    });
    navigate(`/streetview?center=${position.lat},${position.lng}`);
  }, [navigate, position, restrictions, sendEvent]);

  return (
    <Popup offset={new Point(0, -height)}>
      <VStack alignItems={"flex-start"} gap={4}>
        <VStack gap={4} alignItems="flex-start">
          <Text fontSize="md" as="b">
            {title}
          </Text>
          <Text
            fontSize="sm"
            color="darkgray"
            fontWeight={"medium"}
            style={{ margin: 0 }}
          >
            {subtitle}
          </Text>
        </VStack>
        <Button onClick={onClick} variant="secondary" mx="auto">
          Voir dans Street View
        </Button>
      </VStack>
    </Popup>
  );
};
