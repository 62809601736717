import { defineStyle, defineStyleConfig, extendTheme } from "@chakra-ui/react";

const colors = {
  green: "#006E51",
  red: "#C0111E",
  darkgray: "#8C8C8C",

  brand: "#d3e4c8",
  brandDark: "#006E51",
  success: "#006E51",
  warning: "#FFAB2C",
  grayBorder: "#CBD5E0",
};

const fonts = {
  heading: `'Poppins', sans-serif`,
  body: `'Poppins', sans-serif`,
};

const styles = {
  global: {
    body: {
      borderColor: "grayBorder",
    },
  },
};

export const Divider = defineStyleConfig({
  baseStyle: {
    borderColor: "grayBorder",
    size: "1px",
  },
});

export const Switch = defineStyleConfig({
  baseStyle: {
    track: {
      _checked: {
        bg: "green",
      },
    },
  },
});

const primary = defineStyle({
  color: "white",
  bg: "green",
  borderRadius: "3xl",
  _hover: {
    bg: "#00543C",
  },
  _disabled: {
    _hover: {
      bg: "#B2CBC4 !important",
    },
  },
});

const secondary = defineStyle({
  color: "black",
  bg: "white",
  borderRadius: "3xl",
  borderColor: "black",
  borderWidth: "1px",
  _hover: {
    bg: "#f2f2f2",
  },
});

const success = defineStyle({
  color: "white",
  bg: "success",
  _hover: {
    bg: "#6f8e5c",
  },
});

const warning = defineStyle({
  color: "white",
  bg: "warning",
  _hover: {
    bg: "#bd9313",
  },
});

export const Button = defineStyleConfig({
  variants: { primary, secondary, success, warning },
});

export const theme = extendTheme({
  colors,
  fonts,
  styles,
  components: { Divider, Button, Switch },
});
