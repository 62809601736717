import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { useEffect, useState } from "react";
import {
  DirectionControllerGetDirectionsVariables,
  useDirectionControllerGetDirections,
} from "../libs/roadroamer-api/roadroamerApiComponents";

export const useDirection = (
  params: DirectionControllerGetDirectionsVariables
) => {
  const [token, setToken] = useState<string | null>(null);
  const { isLoading: isTokenLoading, getToken } = useKindeAuth();

  useEffect(() => {
    if (isTokenLoading || !getToken) return;
    getToken().then((token) => (token ? setToken(token) : null));
  }, [isTokenLoading, getToken]);

  const {
    data,
    isLoading: isDirectionLoading,
    error,
  } = useDirectionControllerGetDirections(
    { headers: { authorization: `Bearer ${token}` }, ...params },
    {
      refetchOnMount: false,
      enabled: Boolean(token),
    }
  );

  return { data, isLoading: isDirectionLoading || isTokenLoading, error };
};
