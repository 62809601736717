import { Box, VStack, Text } from "@chakra-ui/react";
import { CatalogEntry } from "../libs/roadroamer-api/roadroamerApiSchemas";

type Props = {
  catalogEntry: CatalogEntry;
  selected: boolean;
  onClick: () => void;
};

function getTitle(recurringInterval: CatalogEntry["recurringInterval"]) {
  switch (recurringInterval) {
    case "month":
      return "Mensuel";
    case "year":
      return "Annuel";
    default:
      throw new Error(`Unknown recurring interval: ${recurringInterval}`);
  }
}

function getPriceLabel(
  catalogEntry: Pick<CatalogEntry, "priceInCents" | "recurringInterval">
) {
  switch (catalogEntry.recurringInterval) {
    case "month":
      return `${catalogEntry.priceInCents / 100}€/mois`;
    case "year":
      return `${catalogEntry.priceInCents / 100}€/an`;
    default:
      throw new Error(
        `Unknown recurring interval: ${catalogEntry.recurringInterval}`
      );
  }
}

export const SubscriptionOption = ({
  catalogEntry,
  selected,
  onClick,
}: Props) => {
  return (
    <VStack
      cursor="pointer"
      borderWidth={2}
      borderRadius="md"
      boxShadow="md"
      width={160}
      px={5}
      py={3}
      onClick={onClick}
      borderColor={selected ? "success" : "darkgray"}
      textColor={selected ? "success" : "darkgray"}
      position="relative"
    >
      {catalogEntry.discountDisclamerLabel ? (
        <Box
          position="absolute"
          top={-3.5}
          marginInline="auto"
          backgroundColor="orange"
          px={2}
          textColor="white"
          borderRadius="md"
        >
          <Text fontSize={"xs"} as="b">
            {catalogEntry.discountDisclamerLabel}
          </Text>
        </Box>
      ) : null}
      <Box textAlign="center">
        <Box>
          <Text fontWeight="semibold" as="h4" lineHeight="tight" isTruncated>
            {getTitle(catalogEntry.recurringInterval)}
          </Text>
        </Box>
        <Box>
          <Text>{getPriceLabel(catalogEntry)}</Text>
        </Box>
      </Box>
    </VStack>
  );
};
