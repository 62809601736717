import { Bounds } from "../components/types";

export const boundsToPointsArray = (bounds: Bounds): [number, number][] => [
  [bounds.northeast.lat, bounds.northeast.lng],
  [bounds.southwest.lat, bounds.southwest.lng],
];

export const pointsArrayToBounds = (points: [number, number][]): Bounds => ({
  northeast: { lat: points[0][0], lng: points[0][1] },
  southwest: { lat: points[1][0], lng: points[1][1] },
});
