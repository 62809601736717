import { Image } from "@chakra-ui/react";

export function Logo() {
  return (
    <Image
      src={"https://storage.googleapis.com/roadroamer/logo.png"}
      w={{ base: "8rem", lg: "10rem" }}
      alt="logo"
    />
  );
}
