import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { useCallback, useEffect, useState } from "react";
import {
  useUserControllerGetActiveUser,
  useUserControllerUpdateUser,
} from "../libs/roadroamer-api/roadroamerApiComponents";
import { UserMeUpdateBody } from "../libs/roadroamer-api/roadroamerApiSchemas";

export const useUser = () => {
  const [token, setToken] = useState<string | null>(null);
  const { isLoading: isTokenLoading, getToken } = useKindeAuth();

  useEffect(() => {
    if (isTokenLoading || !getToken) return;
    getToken().then((token) => (token ? setToken(token) : null));
  }, [isTokenLoading, getToken]);

  const { data, isLoading } = useUserControllerGetActiveUser(
    { headers: { authorization: `Bearer ${token}` } },
    {
      refetchOnMount: false,
      enabled: Boolean(token),
    }
  );

  const { mutateAsync } = useUserControllerUpdateUser({});

  const updateUser = useCallback(
    async (body: UserMeUpdateBody) => {
      mutateAsync({ headers: { authorization: `Bearer ${token}` }, body });
    },
    [mutateAsync, token]
  );

  return { data, isLoading, updateUser };
};
