import { createBrowserRouter, useRouteError } from "react-router-dom";
import Search from "./Search";
import Directions from "./Directions";
import Streetview from "./Streetview";
import Welcome from "./Welcome";
import ProtectedRoutes from "./ProtectedRoutes";
import Subscription from "./Subscription";
import { useEffect } from "react";
import * as Sentry from "@sentry/react";
import { Center, Text } from "@chakra-ui/react";
import PaymentSuccess from "./PaymentSuccess";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Welcome />,
    errorElement: <SentryRouteErrorFallback />,
  },
  // Protected routes
  {
    element: <ProtectedRoutes />,
    children: [
      {
        path: "/maps",
        element: <Search />,
      },
      {
        path: "/directions/:origin/:destination",
        element: <Directions />,
      },
      {
        path: "/streetview",
        element: <Streetview />,
      },
      {
        path: "/subscription",
        element: <Subscription />,
      },
      {
        path: "/payment-success",
        element: <PaymentSuccess />,
      },
    ],
    errorElement: <SentryRouteErrorFallback />,
  },
]);

function SentryRouteErrorFallback() {
  const routeError = useRouteError();

  useEffect(() => {
    Sentry.captureException(routeError);
  }, [routeError]);

  return (
    <Center>
      <Text>{routeError as string}</Text>
    </Center>
  );
}
