/**
 * Generated by @openapi-codegen
 *
 * @version 1.0
 */
import * as reactQuery from "@tanstack/react-query";
import {
  useRoadroamerApiContext,
  RoadroamerApiContext,
} from "./roadroamerApiContext";
import type * as Fetcher from "./roadroamerApiFetcher";
import { roadroamerApiFetch } from "./roadroamerApiFetcher";
import type * as Schemas from "./roadroamerApiSchemas";

export type AppControllerGetHealthError = Fetcher.ErrorWrapper<undefined>;

export type AppControllerGetHealthVariables =
  RoadroamerApiContext["fetcherOptions"];

export const fetchAppControllerGetHealth = (
  variables: AppControllerGetHealthVariables,
  signal?: AbortSignal
) =>
  roadroamerApiFetch<
    string,
    AppControllerGetHealthError,
    undefined,
    {},
    {},
    {}
  >({ url: "/health", method: "get", ...variables, signal });

export const useAppControllerGetHealth = <TData = string>(
  variables: AppControllerGetHealthVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<string, AppControllerGetHealthError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useRoadroamerApiContext(options);
  return reactQuery.useQuery<string, AppControllerGetHealthError, TData>({
    queryKey: queryKeyFn({
      path: "/health",
      operationId: "appControllerGetHealth",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchAppControllerGetHealth({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type DirectionControllerGetDirectionsPathParams = {
  origin: string;
  destination: string;
};

export type DirectionControllerGetDirectionsQueryParams = {
  vehicleType: string;
  length?: number;
  width?: number;
  height?: number;
  router?: string;
  avoid?: string;
};

export type DirectionControllerGetDirectionsError =
  Fetcher.ErrorWrapper<undefined>;

export type DirectionControllerGetDirectionsVariables = {
  pathParams: DirectionControllerGetDirectionsPathParams;
  queryParams: DirectionControllerGetDirectionsQueryParams;
} & RoadroamerApiContext["fetcherOptions"];

export const fetchDirectionControllerGetDirections = (
  variables: DirectionControllerGetDirectionsVariables,
  signal?: AbortSignal
) =>
  roadroamerApiFetch<
    Schemas.GetDirectionsResponse,
    DirectionControllerGetDirectionsError,
    undefined,
    {},
    DirectionControllerGetDirectionsQueryParams,
    DirectionControllerGetDirectionsPathParams
  >({
    url: "/directions/{origin}/{destination}",
    method: "get",
    ...variables,
    signal,
  });

export const useDirectionControllerGetDirections = <
  TData = Schemas.GetDirectionsResponse
>(
  variables: DirectionControllerGetDirectionsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.GetDirectionsResponse,
      DirectionControllerGetDirectionsError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useRoadroamerApiContext(options);
  return reactQuery.useQuery<
    Schemas.GetDirectionsResponse,
    DirectionControllerGetDirectionsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/directions/{origin}/{destination}",
      operationId: "directionControllerGetDirections",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchDirectionControllerGetDirections(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type UserControllerGetActiveUserError = Fetcher.ErrorWrapper<undefined>;

export type UserControllerGetActiveUserVariables =
  RoadroamerApiContext["fetcherOptions"];

export const fetchUserControllerGetActiveUser = (
  variables: UserControllerGetActiveUserVariables,
  signal?: AbortSignal
) =>
  roadroamerApiFetch<
    Schemas.UsersMeResponse,
    UserControllerGetActiveUserError,
    undefined,
    {},
    {},
    {}
  >({ url: "/users/me", method: "get", ...variables, signal });

export const useUserControllerGetActiveUser = <TData = Schemas.UsersMeResponse>(
  variables: UserControllerGetActiveUserVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.UsersMeResponse,
      UserControllerGetActiveUserError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useRoadroamerApiContext(options);
  return reactQuery.useQuery<
    Schemas.UsersMeResponse,
    UserControllerGetActiveUserError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/users/me",
      operationId: "userControllerGetActiveUser",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchUserControllerGetActiveUser(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type UserControllerUpdateUserError = Fetcher.ErrorWrapper<undefined>;

export type UserControllerUpdateUserVariables = {
  body: Schemas.UserMeUpdateBody;
} & RoadroamerApiContext["fetcherOptions"];

export const fetchUserControllerUpdateUser = (
  variables: UserControllerUpdateUserVariables,
  signal?: AbortSignal
) =>
  roadroamerApiFetch<
    undefined,
    UserControllerUpdateUserError,
    Schemas.UserMeUpdateBody,
    {},
    {},
    {}
  >({ url: "/users/me", method: "post", ...variables, signal });

export const useUserControllerUpdateUser = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      UserControllerUpdateUserError,
      UserControllerUpdateUserVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useRoadroamerApiContext();
  return reactQuery.useMutation<
    undefined,
    UserControllerUpdateUserError,
    UserControllerUpdateUserVariables
  >({
    mutationFn: (variables: UserControllerUpdateUserVariables) =>
      fetchUserControllerUpdateUser({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type PaymentControllerCreateCheckoutSessionError =
  Fetcher.ErrorWrapper<undefined>;

export type PaymentControllerCreateCheckoutSessionVariables = {
  body: Schemas.CreateCheckoutSessionBody;
} & RoadroamerApiContext["fetcherOptions"];

export const fetchPaymentControllerCreateCheckoutSession = (
  variables: PaymentControllerCreateCheckoutSessionVariables,
  signal?: AbortSignal
) =>
  roadroamerApiFetch<
    Schemas.CreateCheckoutSessionResponse,
    PaymentControllerCreateCheckoutSessionError,
    Schemas.CreateCheckoutSessionBody,
    {},
    {},
    {}
  >({
    url: "/payment/create-checkout-session",
    method: "post",
    ...variables,
    signal,
  });

export const usePaymentControllerCreateCheckoutSession = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.CreateCheckoutSessionResponse,
      PaymentControllerCreateCheckoutSessionError,
      PaymentControllerCreateCheckoutSessionVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useRoadroamerApiContext();
  return reactQuery.useMutation<
    Schemas.CreateCheckoutSessionResponse,
    PaymentControllerCreateCheckoutSessionError,
    PaymentControllerCreateCheckoutSessionVariables
  >({
    mutationFn: (variables: PaymentControllerCreateCheckoutSessionVariables) =>
      fetchPaymentControllerCreateCheckoutSession({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type CatalogControllerCatalogError = Fetcher.ErrorWrapper<undefined>;

export type CatalogControllerCatalogVariables =
  RoadroamerApiContext["fetcherOptions"];

export const fetchCatalogControllerCatalog = (
  variables: CatalogControllerCatalogVariables,
  signal?: AbortSignal
) =>
  roadroamerApiFetch<
    Schemas.CatalogResponse,
    CatalogControllerCatalogError,
    undefined,
    {},
    {},
    {}
  >({ url: "/catalog", method: "get", ...variables, signal });

export const useCatalogControllerCatalog = <TData = Schemas.CatalogResponse>(
  variables: CatalogControllerCatalogVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.CatalogResponse,
      CatalogControllerCatalogError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useRoadroamerApiContext(options);
  return reactQuery.useQuery<
    Schemas.CatalogResponse,
    CatalogControllerCatalogError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/catalog",
      operationId: "catalogControllerCatalog",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchCatalogControllerCatalog(
        { ...fetcherOptions, ...variables },
        signal
      ),
    ...options,
    ...queryOptions,
  });
};

export type StripeWebhookControllerWebhookHeaders = {
  ["stripe-signature"]: string;
};

export type StripeWebhookControllerWebhookError =
  Fetcher.ErrorWrapper<undefined>;

export type StripeWebhookControllerWebhookVariables = {
  headers: StripeWebhookControllerWebhookHeaders;
} & RoadroamerApiContext["fetcherOptions"];

export const fetchStripeWebhookControllerWebhook = (
  variables: StripeWebhookControllerWebhookVariables,
  signal?: AbortSignal
) =>
  roadroamerApiFetch<
    Record<string, any>,
    StripeWebhookControllerWebhookError,
    undefined,
    StripeWebhookControllerWebhookHeaders,
    {},
    {}
  >({ url: "/stripe/webhook", method: "post", ...variables, signal });

export const useStripeWebhookControllerWebhook = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Record<string, any>,
      StripeWebhookControllerWebhookError,
      StripeWebhookControllerWebhookVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useRoadroamerApiContext();
  return reactQuery.useMutation<
    Record<string, any>,
    StripeWebhookControllerWebhookError,
    StripeWebhookControllerWebhookVariables
  >({
    mutationFn: (variables: StripeWebhookControllerWebhookVariables) =>
      fetchStripeWebhookControllerWebhook({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type QueryOperation =
  | {
      path: "/health";
      operationId: "appControllerGetHealth";
      variables: AppControllerGetHealthVariables;
    }
  | {
      path: "/directions/{origin}/{destination}";
      operationId: "directionControllerGetDirections";
      variables: DirectionControllerGetDirectionsVariables;
    }
  | {
      path: "/users/me";
      operationId: "userControllerGetActiveUser";
      variables: UserControllerGetActiveUserVariables;
    }
  | {
      path: "/catalog";
      operationId: "catalogControllerCatalog";
      variables: CatalogControllerCatalogVariables;
    };
