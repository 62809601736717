import { Box, Button, Flex, Heading, VStack, Image } from "@chakra-ui/react";
import background from "../assets/background.webp";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";

export default function Welcome() {
  const { login, register } = useKindeAuth();
  return (
    <Flex
      direction="column"
      w="100%"
      h="100vh"
      alignItems="center"
      justifyContent="center"
      backgroundImage={background}
      backgroundSize={"cover"}
    >
      <VStack
        backgroundColor="white"
        p="12"
        borderRadius="lg"
        alignItems="center"
        gap="8"
        textAlign={"center"}
      >
        <Image
          src={"https://storage.googleapis.com/roadroamer/logo.png"}
          w={{ base: "10rem", lg: "12rem" }}
          alt="logo"
        />
        <Box>
          <Heading size="md" color="green">
            Sécurisez vos trajets
          </Heading>
          <Heading size="md" color="green">
            en camping car
          </Heading>
        </Box>
        <Button variant="primary" onClick={() => register()}>
          J'essaie gratuitement
        </Button>
        <Button variant="secondary" onClick={() => login()}>
          Je me connecte
        </Button>
      </VStack>
    </Flex>
  );
}
