import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { usePostHog } from "posthog-js/react";
import { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";

const ProtectedRoutes = () => {
  const { isAuthenticated, isLoading, user } = useKindeAuth();
  const posthog = usePostHog();

  useEffect(() => {
    if (!user || !user.email) return;

    posthog.identify(user.email, {
      email: user.email,
    });
  }, [user, posthog]);

  if (isLoading) {
    return null;
  }

  return isAuthenticated ? <Outlet /> : <Navigate to="/" replace />;
};

export default ProtectedRoutes;
