import "leaflet/dist/leaflet.css";
import { Helmet } from "react-helmet";
import ReactDOM from "react-dom/client";
import { ChakraProvider } from "@chakra-ui/react";
import { RouterProvider } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { theme } from "./theme";
import { router } from "./pages/router";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import * as Sentry from "@sentry/react";

import { PostHogProvider } from "posthog-js/react";
import { GoogleMapsProvider } from "./hooks/useGoogleMaps";
import { KindeProvider } from "@kinde-oss/kinde-auth-react";

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
};

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    /^https:\/\/roadroamer-api\.fly\.dev\/api/,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: process.env.REACT_APP_CONTEXT,
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <QueryClientProvider client={queryClient}>
    <ChakraProvider theme={theme}>
      <PostHogProvider
        apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
        options={options}
      >
        <KindeProvider
          clientId={process.env.REACT_APP_KINDE_CLIENT_ID}
          domain={process.env.REACT_APP_KINDE_DOMAIN || ""}
          logoutUri={process.env.REACT_APP_KINDE_LOGOUT_URL}
          redirectUri={process.env.REACT_APP_KINDE_REDIRECT_URL || ""}
        >
          <GoogleMapsProvider>
            <Helmet htmlAttributes={{ lang: "fr" }}>
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
              />
              <meta charSet="utf-8" />
              <meta http-equiv="Content-Language" content="fr_FR" />
              <meta
                name="google-adsense-account"
                content="ca-pub-9118652282418807"
              />
              <title>RoadRoamer</title>
            </Helmet>
            <RouterProvider router={router} />
          </GoogleMapsProvider>
        </KindeProvider>
      </PostHogProvider>
    </ChakraProvider>
  </QueryClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
