export function displaySeconds(seconds: number) {
  if (seconds < 60) {
    return `${seconds} sec`;
  }
  if (seconds < 3600) {
    return `${Math.floor(seconds / 60)} min`;
  }
  return `${Math.floor(seconds / 3600)} h ${Math.floor(
    (seconds % 3600) / 60
  )} min`;
}
