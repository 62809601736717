import { Polyline } from "react-leaflet";

type RouteProps = {
  steps: {
    path: { lat: number; lng: number }[];
    isDangerous: boolean;
  }[];
  isActive: boolean;
  handleRouteClick: () => void;
};

function getStrokeColor({
  isDangerous,
  isActive,
  isOutline,
}: {
  isDangerous: boolean;
  isActive: boolean;
  isOutline: boolean;
}) {
  if (isActive && isDangerous && isOutline) {
    return "#9d0208";
  }
  if (isActive && isDangerous && !isOutline) {
    return "#d00000";
  }
  if (isActive && !isDangerous && isOutline) {
    return "#1967d2";
  }
  if (isActive && !isDangerous && !isOutline) {
    return "#00b0ff";
  }
  if (!isActive && isOutline) {
    return "#929497";
  }
  if (!isActive && !isOutline) {
    return "#bbbdbf";
  }
}

export const LineRoad = ({ steps, isActive, handleRouteClick }: RouteProps) => {
  return (
    <>
      {steps.map(({ path, isDangerous }, index) => {
        return (
          <div key={`${index}-${path[0].lat},${path[0].lng}`}>
            <Polyline
              positions={path}
              pathOptions={{
                color: getStrokeColor({
                  isDangerous,
                  isActive,
                  isOutline: true,
                }),
                weight: 5,
              }}
              eventHandlers={{
                click: handleRouteClick,
              }}
            />
            <Polyline
              positions={path}
              pathOptions={{
                color: getStrokeColor({
                  isDangerous,
                  isActive,
                  isOutline: false,
                }),
                weight: 3,
              }}
              eventHandlers={{
                click: handleRouteClick,
              }}
            />
          </div>
        );
      })}
    </>
  );
};
