import {
  Button,
  Flex,
  HStack,
  Spinner,
  Text,
  VStack,
  Image,
  Box,
  Divider,
  Link,
  IconButton,
  Center,
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { useCatalogControllerCatalog } from "../libs/roadroamer-api/roadroamerApiComponents";
import { SubscriptionOption } from "../components/SubscriptionOption";
import { usePayment } from "../hooks/usePayment";
import { SubscriptionFeatureDescription } from "../components/SubscriptionFeatureDescription";
import { FAQ_URL } from "../utils/urls";
import { IconArrowLeft } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";

export default function Subscription() {
  const navigate = useNavigate();
  const [subscriptionPriceId, setSubscriptionPriceId] = useState<string>();
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const { data, isLoading } = useCatalogControllerCatalog({});
  const catalog = data?.catalog;
  const { createCheckoutSession } = usePayment();

  useEffect(() => {
    if (catalog) {
      setSubscriptionPriceId(catalog[0].id);
    }
  }, [catalog]);

  const onSubmit = useCallback(async () => {
    if (!subscriptionPriceId) return;
    setIsSubmitLoading(true);
    const { redirectUrl } = await createCheckoutSession(subscriptionPriceId);
    return window.location.assign(redirectUrl);
  }, [createCheckoutSession, subscriptionPriceId]);

  return (
    <Flex direction="column" w="100%" h="100vh" p="4">
      <Box>
        <IconButton
          aria-label="Back"
          icon={<IconArrowLeft />}
          variant="unstyled"
          color="green"
          onClick={() => navigate("/maps")}
        />
      </Box>
      <VStack textAlign="center" spacing="8" maxWidth={400} h="100%" mx="auto">
        <VStack spacing="8">
          <Image
            src={"https://storage.googleapis.com/roadroamer/logo.png"}
            alt="logo"
            maxWidth="60%"
          />
          <Text as="b">
            Choisissez votre abonnement et profitez de Road Roamer en illimité
          </Text>
          <VStack spacing="4" alignItems="flex-start" px="4">
            <SubscriptionFeatureDescription description="Plus de 4 millions de dangers enregistrés en France" />
            <SubscriptionFeatureDescription description="Jusqu’à 3 itinéraires alternatifs par trajet" />
            <SubscriptionFeatureDescription description="Sans engagement" />
          </VStack>
        </VStack>
        <Center h="100%">
          <VStack spacing="8">
            {isLoading ? <Spinner size="md" /> : null}
            {!isLoading && catalog ? (
              <HStack spacing="4" wrap="wrap" justifyContent="center">
                {catalog.map((value) => (
                  <SubscriptionOption
                    key={value.id}
                    catalogEntry={value}
                    onClick={() => setSubscriptionPriceId(value.id)}
                    selected={subscriptionPriceId === value.id}
                  />
                ))}
              </HStack>
            ) : null}
            <Button
              onClick={onSubmit}
              variant="primary"
              isLoading={isSubmitLoading}
            >
              Je souscris dès maintenant
            </Button>
          </VStack>
        </Center>

        <VStack spacing="4" mt="auto">
          <VStack pb="4" spacing="4">
            <Divider />
            <Text>
              Une question ? Visitez notre{" "}
              <Link
                href={FAQ_URL}
                isExternal
                textDecorationLine="underline"
                color="green"
              >
                centre d'aide
              </Link>{" "}
              ou contactez-nous à{" "}
              <Link
                href="mailto:contact.roadroamer@gmail.com"
                textDecorationLine="underline"
                color="green"
              >
                contact.roadroamer@gmail.com
              </Link>
            </Text>
          </VStack>
        </VStack>
      </VStack>
    </Flex>
  );
}
