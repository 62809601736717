import { AccordionButton, HStack, Text, AccordionIcon } from "@chakra-ui/react";
import { displaySeconds } from "../utils/formatTime";
import { displayMeters } from "../utils/formatDistance";
import { displayDangerNumbers } from "../utils/formatDanger";

type Props = {
  duration: number;
  distance: number;
  nbDangers: number;
};
export const DangerAccordionButton = (props: Props) => {
  const hasDangers = Boolean(props.nbDangers);
  const color = hasDangers ? "red" : "green";

  return (
    <AccordionButton borderRadius="lg">
      <HStack alignItems="center" width="100%" spacing="4">
        <HStack justifyContent="space-between" alignItems="center" width="100%">
          <Text>{displaySeconds(props.duration)}</Text>
          <Text color="darkgray">{displayMeters(props.distance)}</Text>
          <Text color={color}>{displayDangerNumbers(props.nbDangers)}</Text>
        </HStack>
        {hasDangers ? <AccordionIcon color={color} /> : null}
      </HStack>
    </AccordionButton>
  );
};
