import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { useCallback, useEffect, useState } from "react";
import { usePaymentControllerCreateCheckoutSession } from "../libs/roadroamer-api/roadroamerApiComponents";

export const usePayment = () => {
  const [token, setToken] = useState<string | null>(null);
  const { isLoading: isTokenLoading, getToken } = useKindeAuth();

  useEffect(() => {
    if (isTokenLoading || !getToken) return;
    getToken().then((token) => (token ? setToken(token) : null));
  }, [isTokenLoading, getToken]);

  const { mutateAsync } = usePaymentControllerCreateCheckoutSession({});

  const createCheckoutSession = useCallback(
    async (subscriptionPriceId: string) => {
      return mutateAsync({
        headers: { authorization: `Bearer ${token}` },
        body: { subscriptionPriceId },
      });
    },
    [token, mutateAsync]
  );

  return { createCheckoutSession, isLoading: isTokenLoading };
};
