import { Box } from "@chakra-ui/react";
import { DangerAccordion } from "./DangerAccordion";
import { DirectionLoader } from "./DirectionLoader";
import { GeoPoint } from "./types";

type Props = {
  isLoading: boolean;
  selectedDirection: { id: string } | undefined;
  handleDirectionSelect: (id: string) => void;
  onPressDanger: (id: string) => void;
  directions: {
    id: string;
    duration: number; //in seconds
    distance: number; //in meters
    dangers: {
      id: string;
      restrictions: {
        type: "height" | "width" | "weight" | "length";
        value: number;
      }[];
      location: GeoPoint;
    }[];
  }[];
};

export const DirectionDesktop = ({
  isLoading,
  directions,
  selectedDirection,
  handleDirectionSelect,
  onPressDanger,
}: Props) => {
  if (isLoading || !selectedDirection) {
    return <DirectionLoader />;
  }

  return (
    <Box overflowY="scroll">
      <DangerAccordion
        directions={directions}
        selectedDirection={selectedDirection}
        handleDirectionSelect={handleDirectionSelect}
        onPressDanger={onPressDanger}
      />
    </Box>
  );
};
