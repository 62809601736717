import {
  Box,
  Button,
  Divider,
  HStack,
  Icon,
  Input,
  Text,
  VStack,
  Flex,
  Switch,
  InputGroup,
  InputRightElement,
  IconButton,
} from "@chakra-ui/react";
import { useGoogleMapsContext } from "../hooks/useGoogleMaps";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { debounce } from "lodash";
import { IconCircle, IconMapPinFilled, IconMapPin } from "@tabler/icons-react";
import { Logo } from "./Logo";
import { Bounds } from "./types";
import { CloseIcon, InfoIcon } from "@chakra-ui/icons";
import { MenuDrawer } from "./MenuDrawer";
import { useNavigate } from "react-router-dom";

type Props = {
  showLogo?: boolean;
  initialOrigin?: string;
  initialDestination?: string;
  initialBounds?: Bounds;
  initialAvoidTolls?: boolean;
  initialAvoidHighways?: boolean;
  onSelectOriginCallback?: (location: { lat: number; lng: number }) => void;
  onSelectDestinationCallback?: (location: {
    lat: number;
    lng: number;
  }) => void;
  setIsSearching?: (isSearching: boolean) => void;
};

export function Navigation({
  initialBounds,
  initialOrigin,
  initialDestination,
  initialAvoidHighways = false,
  initialAvoidTolls = false,
  onSelectOriginCallback,
  onSelectDestinationCallback,
  setIsSearching,
}: Props) {
  const navigate = useNavigate();
  const originRef = useRef<HTMLInputElement>(null);
  const destinationRef = useRef<HTMLInputElement>(null);
  const [origin, setOrigin] = useState<string>("");
  const [destination, setDestination] = useState<string>("");
  const [selectedOrigin, setSelectedOrigin] = useState<string>("");
  const [selectedDestination, setSelectedDestination] = useState<string>("");
  const [isOriginOutOfFrance, setIsOriginOutOfFrance] =
    useState<boolean>(false);
  const [isDestinationOutOfFrance, setIsDestinationOutOfFrance] =
    useState<boolean>(false);
  const {
    autocompleteService,
    placesService,
    sessionToken,
    updateSessionToken,
  } = useGoogleMapsContext();
  const [suggestions, setSuggestions] = useState<
    google.maps.places.AutocompletePrediction[] | null
  >(null);
  const [suggestionVisible, setSuggestionVisible] = useState<boolean>(false);
  const [focusedInput, setFocusedInput] = useState<"origin" | "destination">();
  const [bounds, setBounds] = useState<Bounds | undefined>(initialBounds);
  const [avoidTolls, setAvoidTolls] = useState<boolean>(initialAvoidTolls);
  const [avoidHighways, setAvoidHighways] =
    useState<boolean>(initialAvoidHighways);

  useEffect(() => {
    setIsSearching?.(suggestionVisible);
  }, [setIsSearching, suggestionVisible]);

  useEffect(() => {
    if (initialOrigin) {
      setOrigin(initialOrigin);
      setSelectedOrigin(initialOrigin);
    }
    if (initialDestination) {
      setDestination(initialDestination);
      setSelectedDestination(initialDestination);
    }
  }, [initialDestination, initialOrigin]);

  const getSuggestions = useCallback(
    (input: string) => {
      autocompleteService?.getPlacePredictions(
        {
          input,
          locationBias: new google.maps.Circle({
            center: new google.maps.LatLng(46.42331, 2.62886), // France Center
            radius: 500_000,
          }),
          sessionToken,
        },
        (predictions) => {
          setSuggestions(predictions);
          setSuggestionVisible(true);
        }
      );
    },
    [autocompleteService, sessionToken]
  );

  const getSuggestionsDebounced = useMemo(() => {
    return debounce(getSuggestions, 500);
  }, [getSuggestions]);

  const onTextChange = (str: string) => {
    if (!autocompleteService) return;
    if (focusedInput === "origin") {
      setOrigin(str);
    } else {
      setDestination(str);
    }
    if (str.length < 3) return;
    getSuggestionsDebounced(str);
  };

  const onBlur = () => {
    if (focusedInput === "origin") {
      setOrigin("");
    } else if (focusedInput === "destination") {
      setDestination("");
    }
    setSuggestionVisible(false);
    setIsSearching?.(false);
    setFocusedInput(undefined);
  };

  const onSelect = (placeId: string) => {
    placesService?.getDetails(
      {
        placeId: placeId,
        fields: ["geometry", "formatted_address", "name", "address_components"],
        sessionToken,
      },
      (place, status) => {
        updateSessionToken();
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          const isOutOfFrance = !place?.address_components?.find(
            (component) => {
              return component.long_name === "France";
            }
          );
          if (focusedInput === "origin") {
            setOrigin(place?.formatted_address ?? "");
            setSelectedOrigin(place?.formatted_address ?? "");
            setIsOriginOutOfFrance(isOutOfFrance);
            if (place?.geometry?.location) {
              const { lat, lng } = place.geometry.location.toJSON();
              onSelectOriginCallback?.(place.geometry.location.toJSON());
              setBounds(
                (bounds) =>
                  ({
                    ...bounds,
                    northeast: { lat, lng },
                  } as any)
              );
            }
          } else {
            setDestination(place?.formatted_address ?? "");
            setSelectedDestination(place?.formatted_address ?? "");
            setIsDestinationOutOfFrance(isOutOfFrance);
            if (place?.geometry?.location) {
              const { lat, lng } = place.geometry.location.toJSON();
              onSelectDestinationCallback?.(place.geometry.location.toJSON());
              setBounds(
                (bounds) =>
                  ({
                    ...bounds,
                    southwest: { lat, lng },
                  } as any)
              );
            }
          }
        }
      }
    );
    setFocusedInput(undefined);
    setIsSearching?.(false);
  };

  const displayClearOrigin = focusedInput === "origin" && origin.length > 0;
  const onClearOrigin = () => {
    setOrigin("");
    setSelectedOrigin("");
    originRef.current?.focus();
  };

  const displayClearDestination =
    focusedInput === "destination" && destination.length > 0;
  const onClearDestination = () => {
    setDestination("");
    setSelectedDestination("");
    destinationRef.current?.focus();
  };

  const canNavigate =
    Boolean(selectedDestination) &&
    Boolean(selectedOrigin) &&
    !suggestionVisible &&
    (initialDestination !== selectedDestination ||
      initialOrigin !== selectedOrigin);

  const onGo = () => {
    if (!origin || !destination) return;
    navigate(
      `/directions/${encodeURIComponent(origin)}/${encodeURIComponent(
        destination
      )}?bounds=${JSON.stringify([
        [bounds?.northeast.lat, bounds?.northeast.lng],
        [bounds?.southwest.lat, bounds?.southwest.lng],
      ])}&avoid=${[avoidTolls ? "tolls" : "", avoidHighways ? "highways" : ""]
        .filter((avoid) => avoid)
        .join(",")}`
    );
  };

  return (
    <>
      <Box width="100%">
        <VStack gap="4" p="4">
          <HStack gap="4" width="100%">
            <MenuDrawer />
            <Box mx="auto">
              <Logo />
            </Box>
          </HStack>

          <HStack gap="4" width="100%">
            <Icon as={IconCircle} color="green" />
            <InputGroup>
              <Input
                ref={originRef}
                value={origin}
                onChange={(event) => onTextChange(event.target.value)}
                focusBorderColor="green"
                placeholder="Recherchez un point de départ"
                onBlur={onBlur}
                onFocus={() => setFocusedInput("origin")}
              />
              <InputRightElement onClick={onClearOrigin}>
                <IconButton
                  aria-label="Clear origin"
                  variant="unstyled"
                  icon={<CloseIcon />}
                  size="xs"
                  hidden={!displayClearOrigin}
                />
              </InputRightElement>
            </InputGroup>
          </HStack>
          <HStack gap="4" width="100%">
            <Icon as={IconMapPinFilled} color="green" />
            <InputGroup>
              <Input
                ref={destinationRef}
                value={destination}
                onChange={(event) => onTextChange(event.target.value)}
                onFocus={() => setFocusedInput("destination")}
                onBlur={onBlur}
                focusBorderColor="green"
                placeholder="Recherchez une destination"
              />
              <InputRightElement onClick={onClearDestination}>
                <IconButton
                  aria-label="Clear destination"
                  variant="unstyled"
                  icon={<CloseIcon />}
                  size="xs"
                  hidden={!displayClearDestination}
                />
              </InputRightElement>
            </InputGroup>
          </HStack>
        </VStack>
        {suggestionVisible ? (
          <Box>
            <Divider />
            <VStack py="2" align="flex-start" divider={<Divider />}>
              {suggestions?.map((suggestion) => (
                <Box
                  as="button"
                  display="flex"
                  key={suggestion.place_id}
                  onMouseDown={() => onSelect(suggestion.place_id)}
                  flexDir="row"
                  alignItems="center"
                  textAlign="left"
                  overflow="clip"
                  px="4"
                  py="2"
                  width="100%"
                >
                  <Icon as={IconMapPin} color="darkgray" mr="4" />
                  <Text whiteSpace="nowrap">
                    {suggestion.structured_formatting.main_text}
                  </Text>
                  <Text
                    color="gray.400"
                    textOverflow="ellipsis"
                    noOfLines={1}
                    ml="2"
                  >
                    {suggestion.structured_formatting.secondary_text}
                  </Text>
                </Box>
              ))}
            </VStack>
          </Box>
        ) : null}
        {canNavigate ? (
          <VStack px="4" pb="4" spacing="4">
            <Flex
              flexDir="row"
              justifyContent="space-between"
              alignItems="center"
              w="100%"
            >
              <Text>Eviter les autoroutes</Text>
              <Switch
                size="lg"
                isChecked={avoidHighways}
                onChange={() =>
                  setAvoidHighways((previousState) => !previousState)
                }
              />
            </Flex>
            <Flex
              flexDir="row"
              justifyContent="space-between"
              alignItems="center"
              w="100%"
            >
              <Text>Eviter les sections à péages</Text>
              <Switch
                size="lg"
                isChecked={avoidTolls}
                onChange={() =>
                  setAvoidTolls((previousState) => !previousState)
                }
              />
            </Flex>
            <Button onClick={onGo} mx="auto" variant="primary">
              Analyser mon trajet pour camping-car
            </Button>
            {isOriginOutOfFrance || isDestinationOutOfFrance ? (
              <HStack spacing="2">
                <InfoIcon color="darkgray" />
                <Text color="darkgray">
                  Seuls les dangers en France seront affichés
                </Text>
              </HStack>
            ) : null}
          </VStack>
        ) : null}
      </Box>
    </>
  );
}
