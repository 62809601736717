import { HStack, Icon, Text } from "@chakra-ui/react";
import { IconCheck } from "@tabler/icons-react";

type Props = {
  description: string;
};

export const SubscriptionFeatureDescription = ({ description }: Props) => {
  return (
    <HStack spacing="4">
      <Icon as={IconCheck} color="green" />
      <Text textAlign="left">{description}</Text>
    </HStack>
  );
};
