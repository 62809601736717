import {
  Accordion,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Divider,
  HStack,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import {
  displayRestrictionName,
  displayRestrictionValue,
  displayRestrictionImage,
} from "../utils/formatDanger";
import { GeoPoint } from "./types";
import { DangerAccordionButton } from "./DangerAccordionButton";
import { useState } from "react";

type Props = {
  selectedDirection: { id: string };
  handleDirectionSelect: (id: string) => void;
  onPressDanger: (id: string) => void;
  directions: {
    id: string;
    duration: number; //in seconds
    distance: number; //in meters
    dangers: {
      id: string;
      restrictions: {
        type: "height" | "width" | "weight" | "length";
        value: number;
      }[];
      location: GeoPoint;
    }[];
  }[];
};

export const DangerAccordion = (props: Props) => {
  const [selectedDirectionIndex, setSelectedDirectionIndex] = useState(-1);

  const onSelect = (index: number) => {
    setSelectedDirectionIndex(index);
    if (index === -1) return;
    props.handleDirectionSelect(props.directions[index].id);
  };

  const onPressDanger = (id: string) => {
    setSelectedDirectionIndex(-1);
    props.onPressDanger(id);
  };

  return (
    <Accordion
      allowToggle
      gap="4"
      p="4"
      display="flex"
      flexDir="column"
      index={selectedDirectionIndex}
      onChange={(val) => onSelect(val as number)}
    >
      {props.directions.map((direction, directionIndex) => {
        const hasDangers = Boolean(direction.dangers.length);
        const isSelected = directionIndex === selectedDirectionIndex;
        const selectedColor = hasDangers ? "red" : "green";
        return (
          <AccordionItem key={direction.id} style={{ border: 0 }}>
            <Box
              borderWidth={isSelected ? 2 : 1}
              borderRadius="lg"
              borderColor={isSelected ? selectedColor : "darkgray"}
            >
              <DangerAccordionButton
                distance={direction.distance}
                duration={direction.duration}
                nbDangers={direction.dangers.length}
              />
              {hasDangers ? (
                <AccordionPanel pb={4}>
                  <Divider />
                  <VStack pt="4" spacing="4" divider={<Divider />}>
                    {direction.dangers.map((danger) => (
                      <HStack
                        key={danger.id}
                        width="100%"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <VStack alignItems="flex-start">
                          {danger.restrictions.map((restriction) => (
                            <HStack
                              key={`${danger.id}-${restriction.type}`}
                              justifyContent="flex-start"
                              spacing="4"
                            >
                              <Image
                                src={displayRestrictionImage(restriction.type)}
                              />
                              <VStack alignItems="flex-start" spacing={0}>
                                <Text>
                                  {displayRestrictionName(restriction.type)}
                                </Text>
                                <Text color="darkgray">
                                  {displayRestrictionValue(
                                    restriction.type,
                                    restriction.value
                                  )}
                                </Text>
                              </VStack>
                            </HStack>
                          ))}
                        </VStack>
                        <Button
                          variant="secondary"
                          onClick={() => onPressDanger(danger.id)}
                        >
                          Visualiser
                        </Button>
                      </HStack>
                    ))}
                  </VStack>
                </AccordionPanel>
              ) : null}
            </Box>
          </AccordionItem>
        );
      })}
    </Accordion>
  );
};
