import markerIconPng from "leaflet/dist/images/marker-icon.png";
import { Icon } from "leaflet";
import { GeoPoint } from "../types";
import { Marker } from "react-leaflet";

type Props = {
  position: GeoPoint;
};
export const MarkerPin = ({ position }: Props) => {
  const icon = new Icon({
    iconUrl: markerIconPng,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
  });
  return <Marker position={position} icon={icon} />;
};
