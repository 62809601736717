import { Box, Text, Icon, Flex } from "@chakra-ui/react";
import { IconCircleArrowRightFilled } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";

export const ButtonSubscription = () => {
  const navigate = useNavigate();

  return (
    <Box
      as="button"
      onClick={() => navigate("/subscription")}
      backgroundColor="green"
      borderRadius="lg"
      p="4"
      color="white"
      textAlign="left"
    >
      <Text as="b" textAlign="left">
        Abonnement Road Roamer
      </Text>
      <Flex flexDir="row" alignItems="center" backgroundColor="red.300">
        {/* <Box maxW="30%"> */}
        <Text>Accédez à toutes les fonctionnalités en illimité</Text>
        {/* </Box> */}
        <Icon as={IconCircleArrowRightFilled} boxSize="6" />
      </Flex>
    </Box>
  );
};
