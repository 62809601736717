import { Icon } from "leaflet";
import { GeoPoint } from "../types";
import { dangerMarkerIcon } from "../../utils/formatDanger";
import { Marker } from "react-leaflet";
import { PopupDanger } from "./PopupDanger";

type Props = {
  position: GeoPoint;
  restrictions: {
    weight?: number;
    width?: number;
    height?: number;
    length?: number;
  };
};

export const MarkerDanger = ({ position, restrictions }: Props) => {
  const icon = new Icon(dangerMarkerIcon(restrictions));
  const [width, height] = (icon.options.iconSize as Array<number>) || [0, 0];

  return (
    <Marker position={position} icon={icon}>
      <PopupDanger
        iconSize={[width, height]}
        position={position}
        restrictions={restrictions}
      />
    </Marker>
  );
};
