import type { Map } from "leaflet";
import { GeoPoint, IMarker } from "../types";
import { MapContainer, TileLayer } from "react-leaflet";
import { MarkerPin } from "./MarkerPin";
import { MarkerDanger } from "./MarkerDanger";
import { LineRoad } from "./LineRoad";

type MapProps = {
  setMap: (map: Map) => void;
  center?: GeoPoint;
  markers?: (IMarker & { id: string })[];
  bounds?: [number, number][];
  routes?: {
    id: string;
    steps: {
      path: { lat: number; lng: number }[];
      isDangerous: boolean;
    }[];
    dangers: IMarker[];
  }[];
  selectedRoute?: {
    id: string;
    steps: {
      path: { lat: number; lng: number }[];
      isDangerous: boolean;
    }[];
    dangers: IMarker[];
  };
  handleRouteSelect?: (id: string) => void;
  points?: GeoPoint[];
};

export function MapLeaflet({
  setMap,
  markers,
  center,
  routes,
  selectedRoute,
  bounds,
  points,
  handleRouteSelect,
}: MapProps) {
  const firstPoint =
    routes && routes.length ? routes[0].steps[0].path[0] : null;
  const lastPoint =
    routes && routes.length ? routes[0].steps[0].path.slice(-1)[0] : null;

  const onSelectRoute = (id: string) => {
    if (!handleRouteSelect) return;
    handleRouteSelect(id);
  };

  return (
    <MapContainer
      ref={setMap}
      center={center}
      bounds={bounds}
      zoomControl={false}
      style={{ height: "100vh", width: "100%", zIndex: 0 }}
    >
      {(routes || [])
        .filter((route) => route.id !== selectedRoute?.id)
        .map((route) => (
          <LineRoad
            key={route.id}
            steps={route.steps}
            isActive={false}
            handleRouteClick={() => onSelectRoute(route.id)}
          />
        ))}
      {selectedRoute ? (
        <LineRoad
          key={selectedRoute.id}
          steps={selectedRoute.steps}
          isActive
          handleRouteClick={() => {}}
        />
      ) : null}
      {(markers || []).map((marker) => (
        <MarkerDanger
          key={marker.id}
          position={marker.point}
          restrictions={marker.restrictions}
        />
      ))}
      {firstPoint ? <MarkerPin position={firstPoint} /> : null}
      {lastPoint ? <MarkerPin position={lastPoint} /> : null}
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
    </MapContainer>
  );
}
