import { Divider, VStack, HStack, Icon, Text } from "@chakra-ui/react";
import { IconCalendar } from "@tabler/icons-react";
import { Subscription } from "../libs/roadroamer-api/roadroamerApiSchemas";
import { ButtonSubscription } from "./ButtonSubscription";

type Props = {
  expiresAt: Subscription["expiresAt"];
};

function daysUntilLabel(date: string) {
  const now = new Date();
  const expiresAt = new Date(date);
  const diff = expiresAt.getTime() - now.getTime();
  const diffDays = Math.ceil(diff / (1000 * 60 * 60 * 24));
  if (diffDays > 1) {
    return `Plus que ${diffDays} jours restants`;
  }
  if (diffDays < 1 && diffDays > 0) {
    return `Moins d'un jour restant`;
  }
  return `Votre essai gratuit a expiré`;
}

export const MenuEntryFreeTrial = ({ expiresAt }: Props) => {
  return (
    <>
      <Divider />
      <VStack spacing={4} align="stretch">
        <Text as="b">Mon essai gratuit</Text>
        <HStack>
          <Icon as={IconCalendar} color="green" />
          <Text>{daysUntilLabel(expiresAt)}</Text>
        </HStack>
        <ButtonSubscription />
      </VStack>
    </>
  );
};
