import { MapContainer, TileLayer } from "react-leaflet";

export const MapEmpty = (props: React.ComponentProps<typeof MapContainer>) => {
  return (
    <MapContainer zoomControl={false} {...props}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
    </MapContainer>
  );
};
