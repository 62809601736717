import { Box, Flex } from "@chakra-ui/react";
import { Navigation } from "../components/Navigation";
import { useEffect, useMemo, useState } from "react";
import { useDisplay } from "../hooks/useDisplay";
import { MapContainer, TileLayer } from "react-leaflet";
import type { Map } from "leaflet";
import { MarkerPin } from "../components/leaflet/MarkerPin";
import { ModalFirstTime } from "../components/ModalFirstTime";
import { ModalSubscriptionEnded } from "../components/ModalSubscriptionEnded";

export default function Search() {
  const [map, setMap] = useState<Map | null>(null);
  const [origin, setOrigin] = useState<{ lat: number; lng: number }>();
  const [destination, setDestination] = useState<{
    lat: number;
    lng: number;
  }>();
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const { isMobile } = useDisplay();
  const displayMap = !(isSearching && isMobile);

  const center = useMemo(() => {
    if (origin && destination) {
      return {
        lat: (origin.lat + destination.lat) / 2,
        lng: (origin.lng + destination.lng) / 2,
      };
    }

    if (origin) return origin;
    if (destination) return destination;

    return {
      lat: 46.16264640877203,
      lng: 2.4084807739366516,
    };
  }, [origin, destination]);

  useEffect(() => {
    if (!map) return;
    map.flyTo(center);
  }, [center, map]);

  useEffect(() => {
    if (map && origin && destination) {
      map.flyToBounds(
        [
          [origin.lat, origin.lng],
          [destination.lat, destination.lng],
        ],
        {
          paddingBottomRight: [10, isMobile ? 300 : 10],
          paddingTopLeft: [10, 10],
        }
      );
    }
  }, [origin, destination, map, isMobile]);

  const points = useMemo(() => {
    const result = [];
    if (origin) result.push(origin);
    if (destination) result.push(destination);
    return result;
  }, [origin, destination]);

  return (
    <>
      <Flex direction={{ base: "column" }} h="100%" w="100%">
        <Box
          position={{ base: "relative", lg: "absolute" }}
          width={{ base: "100%", lg: "25%" }}
          marginTop={{ base: 0, lg: "4" }}
          marginLeft={{ base: 0, lg: "4" }}
          minWidth="25%"
          zIndex={1}
          backgroundColor="white"
        >
          <Navigation
            onSelectOriginCallback={setOrigin}
            onSelectDestinationCallback={setDestination}
            setIsSearching={setIsSearching}
            initialAvoidHighways
            initialAvoidTolls
          />
        </Box>

        {displayMap ? (
          <MapContainer
            center={center}
            zoom={6}
            zoomControl={false}
            ref={setMap}
            style={{ height: "100vh", width: "100%", zIndex: 0 }}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {points.map((point, index) => (
              <MarkerPin key={index} position={point} />
            ))}
          </MapContainer>
        ) : null}
      </Flex>
      <ModalFirstTime />
      <ModalSubscriptionEnded />
    </>
  );
}
