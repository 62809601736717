import { Libraries, useJsApiLoader } from "@react-google-maps/api";
import { useEffect, useMemo, useState } from "react";
import constate from "constate";

const LIBRARIES: Libraries = ["places"];

function useGoogleMaps() {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "",
    libraries: LIBRARIES,
  });
  const [sessionToken, setSessionToken] =
    useState<google.maps.places.AutocompleteSessionToken>();

  const autocompleteService = useMemo(() => {
    if (!isLoaded) return null;
    return new google.maps.places.AutocompleteService();
  }, [isLoaded]);

  const placesService = useMemo(() => {
    if (!isLoaded) return null;
    return new google.maps.places.PlacesService(document.createElement("div"));
  }, [isLoaded]);

  useEffect(() => {
    if (!isLoaded) return;
    setSessionToken(new google.maps.places.AutocompleteSessionToken());
  }, [isLoaded]);

  const updateSessionToken = () => {
    setSessionToken(new google.maps.places.AutocompleteSessionToken());
  };

  return {
    isLoaded,
    autocompleteService,
    placesService,
    sessionToken,
    updateSessionToken,
  };
}

export const [GoogleMapsProvider, useGoogleMapsContext] =
  constate(useGoogleMaps);
