import { Center, Spinner, Text } from "@chakra-ui/react";

export const DirectionLoader = () => {
  return (
    <Center p="4" width="100%" gap="4" flexDir="column">
      <Spinner size="xl" />
      <Text>Recherche des dangers sur votre itinéraire....</Text>
    </Center>
  );
};
