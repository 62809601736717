import { Button, Flex, Image, Text, VStack } from "@chakra-ui/react";
import Confetti from "react-confetti";
import { useWindowSize, useTimeout } from "react-use";
import background from "../assets/background.webp";
import { useNavigate } from "react-router-dom";

export default function PaymentSuccess() {
  const navigate = useNavigate();
  const { width, height } = useWindowSize();
  const [isComplete] = useTimeout(4000);

  return (
    <>
      <Confetti width={width} height={height} recycle={!isComplete()} />
      <Flex
        direction="column"
        w="100%"
        h="100vh"
        alignItems="center"
        justifyContent="center"
        backgroundImage={background}
        backgroundSize={"cover"}
      >
        <VStack
          spacing="8"
          backgroundColor="white"
          p="8"
          borderRadius="lg"
          width={{ base: "70%", lg: "30%" }}
        >
          <Image
            src={"https://storage.googleapis.com/roadroamer/logo.png"}
            w={{ base: "10rem", lg: "12rem" }}
            alt="logo"
          />
          <Text fontSize={64}>🎉</Text>
          <Text as="b" fontSize="2xl" color="green">
            Félicitations !
          </Text>
          <Text textAlign="center">
            Vous êtes maintenant abonné à Road Roamer et pouvez profiter de
            notre service en illimité.
          </Text>
          <Button variant="primary" onClick={() => navigate("/maps")}>
            C'est parti !
          </Button>
        </VStack>
      </Flex>
    </>
  );
}
