import { BaseIconOptions } from "leaflet";

export function displayDangerNumbers(nbDangers: number) {
  if (nbDangers === 0) {
    return "Aucun danger";
  }
  return `${nbDangers} dangers`;
}

export function displayRestrictionImage(
  type: "height" | "width" | "weight" | "length"
) {
  switch (type) {
    case "height":
      return "https://storage.googleapis.com/roadroamer/height-restriction.png";
    case "width":
      return "https://storage.googleapis.com/roadroamer/width-restriction.png";
    case "weight":
      return "https://storage.googleapis.com/roadroamer/weight-restriction.png";
    case "length":
      return "https://storage.googleapis.com/roadroamer/length-restriction.png";
  }
}

export function displayRestrictionName(
  type: "height" | "width" | "weight" | "length"
) {
  switch (type) {
    case "height":
      return "Restriction hauteur";
    case "width":
      return "Restriction largeur";
    case "weight":
      return "Restriction poids";
    case "length":
      return "Restriction longueur";
  }
}

export function displayRestrictionValue(
  type: "height" | "width" | "weight" | "length",
  value: number
) {
  switch (type) {
    case "height":
      return `max ${value} m`;
    case "width":
      return `max ${value} m`;
    case "weight":
      return `max ${value} t`;
    case "length":
      return `max ${value} m`;
  }
}

export function dangerLabel(restrictions: {
  weight?: number;
  width?: number;
  height?: number;
  length?: number;
}) {
  let title = "Restriction ";
  let subtitle = "";
  let hasDanger = false;
  if (restrictions.height) {
    title += " hauteur";
    subtitle = `max ${restrictions.height} m`;
    hasDanger = true;
  }
  if (restrictions.weight) {
    title += `${hasDanger ? " / " : ""}poids`;
    subtitle += `${hasDanger ? " / " : ""}max ${restrictions.weight} t`;
    hasDanger = true;
  }
  if (restrictions.width) {
    title += `${hasDanger ? " / " : ""}largeur`;
    subtitle += `${hasDanger ? " / " : ""}max ${restrictions.width} m`;
    hasDanger = true;
  }

  return {
    title,
    subtitle,
  };
}

export function dangerMarkerIcon({
  weight,
  height,
  width,
  length,
}: {
  weight?: number;
  width?: number;
  height?: number;
  length?: number;
}): BaseIconOptions {
  switch (
    `${Boolean(height)}|${Boolean(weight)}|${Boolean(width)}|${Boolean(length)}`
  ) {
    case "true|true|true|true":
      return {
        iconUrl:
          "https://storage.googleapis.com/roadroamer/height-weight-width-length-restriction-marker-map.png",
        iconSize: [70, 192],
        iconAnchor: [35, 192],
      };
    case "true|true|false|true":
      return {
        iconUrl:
          "https://storage.googleapis.com/roadroamer/height-weight-length-restriction-marker-map.png",
        iconSize: [70, 143],
        iconAnchor: [35, 143],
      };
    case "true|false|true|true":
      return {
        iconUrl:
          "https://storage.googleapis.com/roadroamer/height-width-length-restriction-marker-map.png",
        iconSize: [70, 143],
        iconAnchor: [35, 143],
      };
    case "false|true|true|true":
      return {
        iconUrl:
          "https://storage.googleapis.com/roadroamer/weight-width-length-restriction-marker-map.png",
        iconSize: [70, 143],
        iconAnchor: [35, 143],
      };
    case "true|false|false|true":
      return {
        iconUrl:
          "https://storage.googleapis.com/roadroamer/height-length-restriction-marker-map.png",
        iconSize: [70, 95],
        iconAnchor: [35, 95],
      };
    case "false|true|false|true":
      return {
        iconUrl:
          "https://storage.googleapis.com/roadroamer/weight-length-restriction-marker-map.png",
        iconSize: [70, 95],
        iconAnchor: [35, 95],
      };
    case "false|false|true|true":
      return {
        iconUrl:
          "https://storage.googleapis.com/roadroamer/width-length-restriction-marker-map.png",
        iconSize: [70, 95],
        iconAnchor: [35, 95],
      };
    case "false|false|false|true":
      return {
        iconUrl:
          "https://storage.googleapis.com/roadroamer/length-restriction-marker-map.png",
        iconSize: [50, 53],
        iconAnchor: [25, 53],
      };
    case "true|true|true|false":
      return {
        iconUrl:
          "https://storage.googleapis.com/roadroamer/height-weight-width-restriction-marker-map.png",
        iconSize: [70, 143],
        iconAnchor: [35, 143],
      };
    case "true|true|false|false":
      return {
        iconUrl:
          "https://storage.googleapis.com/roadroamer/height-weight-restriction-marker-map.png",
        iconSize: [70, 95],
        iconAnchor: [35, 95],
      };
    case "true|false|true|false":
      return {
        iconUrl:
          "https://storage.googleapis.com/roadroamer/height-width-restriction-marker-map.png",
        iconSize: [70, 95],
        iconAnchor: [35, 95],
      };
    case "false|true|true|false":
      return {
        iconUrl:
          "https://storage.googleapis.com/roadroamer/weight-width-restriction-marker-map.png",
        iconSize: [70, 95],
        iconAnchor: [35, 95],
      };
    case "true|false|false|false":
      return {
        iconUrl:
          "https://storage.googleapis.com/roadroamer/height-restriction-marker-map.png",
        iconSize: [50, 53],
        iconAnchor: [25, 53],
      };
    case "false|true|false|false":
      return {
        iconUrl:
          "https://storage.googleapis.com/roadroamer/weight-restriction-marker-map.png",
        iconSize: [50, 53],
        iconAnchor: [25, 53],
      };
    case "false|false|true|false":
      return {
        iconUrl:
          "https://storage.googleapis.com/roadroamer/width-restriction-marker-map.png",
        iconSize: [50, 53],
        iconAnchor: [25, 53],
      };
    default:
      return {
        iconUrl:
          "https://storage.googleapis.com/roadroamer/width-restriction-marker-map.png",
        iconSize: [50, 53],
        iconAnchor: [25, 53],
      };
  }
}
