import { useMediaQuery } from "@chakra-ui/react";

export function useDisplay() {
  const [isDesktop] = useMediaQuery("(min-width: 62em)");

  return {
    isDesktop,
    isMobile: !isDesktop,
  };
}
