import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Flex, Text } from "@chakra-ui/react";
import { Marker, type Map } from "leaflet";
import { GetDirectionsResponseDirection } from "../libs/roadroamer-api/roadroamerApiSchemas";
import { Navigation } from "../components/Navigation";
import { useDisplay } from "../hooks/useDisplay";
import { DirectionMobileModale } from "../components/DirectionMobileModale";
import { DirectionDesktop } from "../components/DirectionDesktop";
import { useAnalytics } from "../libs/analytics";
import { DirectionsLoaded } from "../libs/analytics/events";
import { boundsToPointsArray, pointsArrayToBounds } from "../utils/bounds";
import { MapEmpty } from "../components/leaflet/MapEmpty";
import { MapLeaflet } from "../components/leaflet/Map";
import { useDirection } from "../hooks/useDirection";
import { ModalSubscriptionEnded } from "../components/ModalSubscriptionEnded";

export default function Directions() {
  const [map, setMap] = useState<Map | null>(null);
  const { isMobile, isDesktop } = useDisplay();
  const { origin, destination } = useParams();
  const { sendEvent } = useAnalytics();
  if (!origin || !destination) {
    throw new Error("Missing origin or destination");
  }

  const urlSearchParams = new URLSearchParams(window.location.search);
  const [bounds, setBounds] = useState<[number, number][] | undefined>(() => {
    const boundsUrl = urlSearchParams.get("bounds") || undefined;
    if (!boundsUrl) return undefined;
    return JSON.parse(boundsUrl) as [number, number][];
  });

  const [isSearching, setIsSearching] = useState<boolean>(false);
  const vehicleType = urlSearchParams.get("vehicleType") || "heavy";
  const router = urlSearchParams.get("router") || undefined;
  const displayMap = !(isSearching && isMobile);
  const avoid = urlSearchParams.get("avoid") || undefined;

  const {
    data,
    isLoading: isDirectionLoading,
    error,
  } = useDirection({
    pathParams: {
      origin: encodeURIComponent(origin),
      destination: encodeURIComponent(destination),
    },
    queryParams: { vehicleType, router, avoid },
  });
  const [selectedRoute, setSelectedRoute] =
    useState<GetDirectionsResponseDirection>();

  const directions = useMemo(() => {
    if (!data?.directions) return [];
    setSelectedRoute(data?.directions[0]);
    setBounds(boundsToPointsArray(data?.directions[0].bounds));
    sendEvent<DirectionsLoaded>({
      name: "directions:loaded",
      properties: {
        directions: data.directions.map((direction) => ({
          duration: direction.duration,
          distance: direction.distance,
          dangers: direction.dangers.map((danger) => ({
            restrictions: danger.restrictions,
            position: danger.point,
          })),
          nbDangers: direction.dangers.length,
        })),
        nbDirections: data.directions.length,
        origin,
        destination,
        avoidTolls: avoid?.includes("tolls") || false,
        avoidHighways: avoid?.includes("highways") || false,
      },
    });
    return data.directions;
  }, [data, avoid, origin, destination, sendEvent]);

  const handleRouteSelect = useCallback(
    (id: string) => {
      if (!data) return;
      const newSelectedRoute =
        data.directions.find((itinerary) => itinerary.id === id) || null;
      if (newSelectedRoute) {
        setSelectedRoute(newSelectedRoute);
        setBounds(boundsToPointsArray(newSelectedRoute.bounds));
      }
    },
    [data, setSelectedRoute]
  );

  const handlePressDanger = useCallback(
    (id: string) => {
      const danger = selectedRoute?.dangers.find((danger) => danger.id === id);
      if (!danger) return;
      map?.eachLayer((layer) => {
        if (layer instanceof Marker) {
          if (
            layer.getLatLng().lat === danger.point.lat &&
            layer.getLatLng().lng === danger.point.lng
          ) {
            map.flyTo(layer.getLatLng(), 18, {
              duration: 1,
            });
            layer.openPopup();
          }
        }
      });
    },
    [selectedRoute, map]
  );

  useEffect(() => {
    if (!map || !bounds) return;
    map.flyToBounds(bounds, {
      paddingBottomRight: [10, isMobile ? 300 : 10],
      paddingTopLeft: [10, 10],
      duration: 1,
    });
  }, [bounds, map, isMobile]);

  if (error) {
    return (
      <Flex bg="white" direction="column" w="100%">
        <Text margin="auto" maxW="40%" textAlign="center">
          {(error as any)?.stack?.message ||
            "Une erreur est survenue, veuillez réessayer."}
        </Text>
      </Flex>
    );
  }

  return (
    <>
      <Flex direction="column" w="100%" h="100vh">
        <Flex direction={{ base: "column", lg: "row" }} h="100%">
          <Flex
            width={{ base: "100%", lg: "30%" }}
            minWidth="30%"
            flexDir="column"
          >
            <Navigation
              showLogo={isDesktop}
              initialBounds={bounds ? pointsArrayToBounds(bounds) : undefined}
              initialOrigin={origin}
              initialDestination={destination}
              initialAvoidTolls={avoid?.includes("tolls")}
              initialAvoidHighways={avoid?.includes("highways")}
              setIsSearching={setIsSearching}
            />
            {isDesktop ? (
              <DirectionDesktop
                isLoading={isDirectionLoading}
                selectedDirection={selectedRoute}
                handleDirectionSelect={handleRouteSelect}
                onPressDanger={handlePressDanger}
                directions={directions.map((direction) => ({
                  id: direction.id,
                  duration: direction.duration,
                  distance: direction.distance,
                  dangers: direction.dangers.map((danger) => ({
                    id: danger.id,
                    restrictions: Object.entries(danger.restrictions)
                      .filter(([_, value]) => Boolean(value))
                      .map(([type, value]) => ({
                        type: type as "height" | "width" | "weight" | "length",
                        value: value,
                      })),
                    location: danger.point,
                  })),
                }))}
              />
            ) : null}
          </Flex>
          {displayMap ? (
            directions && selectedRoute && !isDirectionLoading ? (
              <MapLeaflet
                setMap={setMap}
                center={selectedRoute.steps[0].path[0]}
                markers={selectedRoute.dangers}
                routes={directions}
                selectedRoute={selectedRoute}
                handleRouteSelect={handleRouteSelect}
                bounds={bounds}
              />
            ) : (
              <MapEmpty
                bounds={bounds}
                style={{ height: "100vh", width: "100%", zIndex: 0 }}
              />
            )
          ) : null}

          {isMobile ? (
            <DirectionMobileModale
              isLoading={isDirectionLoading}
              selectedDirection={selectedRoute}
              handleDirectionSelect={handleRouteSelect}
              onPressDanger={handlePressDanger}
              directions={directions.map((direction) => ({
                id: direction.id,
                duration: direction.duration,
                distance: direction.distance,
                dangers: direction.dangers.map((danger) => ({
                  id: danger.id,
                  restrictions: Object.entries(danger.restrictions)
                    .filter(([_, value]) => Boolean(value))
                    .map(([type, value]) => ({
                      type: type as "height" | "width" | "weight" | "length",
                      value: value,
                    })),
                  location: danger.point,
                })),
              }))}
            />
          ) : null}
        </Flex>
      </Flex>
      <ModalSubscriptionEnded />
    </>
  );
}
