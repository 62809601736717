import {
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Icon,
  Image,
  VStack,
  Text,
  Box,
  Link as ChakraLink,
  useDisclosure,
  Spinner,
  Center,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import {
  IconUser,
  IconLogout,
  IconHelp,
  IconMessage2,
  IconSettings,
} from "@tabler/icons-react";
import { useRef } from "react";
import { useUser } from "../hooks/useUser";
import { MenuEntryFreeTrial } from "./MenuEntryFreeTrial";
import { FAQ_URL } from "../utils/urls";

export const MenuDrawer = () => {
  const { logout } = useKindeAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef<HTMLButtonElement | null>(null);
  const { data: user, isLoading } = useUser();

  return (
    <>
      <Box as="button" aria-label="User menu" ref={btnRef} onClick={onOpen}>
        <Icon as={HamburgerIcon} color="green" boxSize={6} />
      </Box>
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            <Image
              src={"https://storage.googleapis.com/roadroamer/logo.png"}
              w={{ base: "8rem", lg: "10rem" }}
              alt="logo"
            />
          </DrawerHeader>

          <DrawerBody>
            {!isLoading ? (
              <VStack spacing={4} align="stretch">
                {user?.activeSubscription &&
                user?.activeSubscription.type === "TRIAL" ? (
                  <MenuEntryFreeTrial
                    expiresAt={user.activeSubscription.expiresAt}
                  />
                ) : null}
                <Divider />
                <VStack spacing={4} align="stretch">
                  <Text as="b">Mon compte</Text>
                  {user ? (
                    <HStack>
                      <Icon as={IconUser} color="green" />
                      <Text>{user.email}</Text>
                    </HStack>
                  ) : null}
                  {user?.stripeCustomerPortalLink ? (
                    <ChakraLink
                      href={user?.stripeCustomerPortalLink}
                      isExternal
                    >
                      <HStack>
                        <Icon as={IconSettings} color="green" />
                        <Text>Gérer mon abonnement</Text>
                      </HStack>
                    </ChakraLink>
                  ) : null}
                  <Box as="button" onClick={logout}>
                    <HStack color="darkgray">
                      <Icon as={IconLogout} />
                      <Text>Se deconnecter</Text>
                    </HStack>
                  </Box>
                </VStack>
                <Divider />
                <VStack spacing={4} align="stretch">
                  <ChakraLink href={FAQ_URL} isExternal>
                    <HStack>
                      <Icon as={IconHelp} color="green" />
                      <Text>Centre d'aide</Text>
                    </HStack>
                  </ChakraLink>
                  <ChakraLink href="mailto:contact.roadroamer@gmail.com">
                    <HStack>
                      <Icon as={IconMessage2} color="green" />
                      <Text>Contactez-nous</Text>
                    </HStack>
                  </ChakraLink>
                </VStack>
              </VStack>
            ) : (
              <Center>
                <Spinner size="lg" />
              </Center>
            )}
          </DrawerBody>

          <DrawerFooter></DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};
