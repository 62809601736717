import { useRef } from "react";
import Sheet, { SheetRef } from "react-modal-sheet";
import { GeoPoint } from "./types";
import { DangerAccordion } from "./DangerAccordion";
import { DirectionLoader } from "./DirectionLoader";
import { Theme, useTheme } from "@chakra-ui/react";

type Props = {
  isLoading: boolean;
  selectedDirection: { id: string } | undefined;
  handleDirectionSelect: (id: string) => void;
  onPressDanger: (id: string) => void;
  directions: {
    id: string;
    duration: number; //in seconds
    distance: number; //in meters
    dangers: {
      id: string;
      restrictions: {
        type: "height" | "width" | "weight" | "length";
        value: number;
      }[];
      location: GeoPoint;
    }[];
  }[];
};

export const DirectionMobileModale = ({
  isLoading,
  directions,
  handleDirectionSelect,
  onPressDanger,
  selectedDirection,
}: Props) => {
  const theme = useTheme<Theme>();
  const ref = useRef<SheetRef>();
  const snapTo = (i: number) => ref.current?.snapTo(i);

  const handlePressDanger = (id: string) => {
    snapTo(1);
    onPressDanger(id);
  };

  return (
    <Sheet
      isOpen
      ref={ref}
      snapPoints={[400]}
      onClose={() => snapTo(0)}
      detent="content-height"
    >
      <Sheet.Container>
        <Sheet.Content style={{ marginTop: theme.space["4"] }}>
          {isLoading || !selectedDirection ? (
            <DirectionLoader />
          ) : (
            <Sheet.Scroller>
              <DangerAccordion
                directions={directions}
                selectedDirection={selectedDirection}
                handleDirectionSelect={handleDirectionSelect}
                onPressDanger={handlePressDanger}
              />
            </Sheet.Scroller>
          )}
        </Sheet.Content>
      </Sheet.Container>
    </Sheet>
  );
};
